<template>
  <nav class="funnel-navbar row">
    <div class="col-12">
      <div class="row d-flex align-items-center justify-content-center">
        <div class="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start">
          <HButton
            tag="link"
            target="_blank"
            :to="localePath('/mentions-legales')"
            color="night-blue"
            inverted
            :block="smallerThanMd"
            class="mt-3 mt-md-0"
          >
            Mentions légales
          </HButton>
        </div>
        <div class="col-12 col-md-4 d-flex align-items-center justify-content-center order-first order-md-0">
          <slot
            name="logo"
          >
            <NuxtLink
              :to="localePath('index')"
              aria-label="logo homeloop"
            >
              <SvgLogoBlue class="funnel-navbar__logo" />
            </NuxtLink>
          </slot>
        </div>
        <div class="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
          <HButton
            tag="link"
            target="_blank"
            color="night-blue"
            inverted
            to="/contact"
            :block="smallerThanMd"
            class="mt-3 mt-md-0"
          >
            Nous contacter
          </HButton>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
const viewport = useViewport()
const smallerThanMd = computed(() => viewport.isLessThan('md'))

</script>

<style lang="scss" scoped>

.funnel-navbar {
  background-color: rgba($background, 90%) !important;
  backdrop-filter: blur(20px);
  box-shadow: 0 0 10px 2px rgba(0 0 0 / 10%);
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: sticky;
  top: 0;
  z-index: 2;

  .row {
    justify-content: center;
  }

  &__logo {
    width: 150px;
  }
}
</style>
